import axios, {type AxiosError, AxiosHeaders, type AxiosInstance, type InternalAxiosRequestConfig,} from "axios";
import {AxiosCacheInstance, setupCache} from "axios-cache-interceptor";
import {inject} from "vue";

import {ApiError, ApiValidationError} from "@/errors";
import type {Model} from "@/helpers/store";
import ChatIO from "@/io/chats";
import MessageIO from "@/io/messages";
import type {SetupIntent} from "@/io/setup_intents";
import {ApiInjectionKey} from "@/plugins/symbols";
import type {IPaymentMethod} from "@/store/PaymentMethods";
import type {Conversation} from "@/store/chatter/chats";
import type {IBooking} from "@/store/rental/models";
import type {Payment, Payout} from "@/store/transactions";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {until} from "@vueuse/core";
import {Logger} from "@/app/logger";

const MAX_AUTH_RETRIES = 1;

Object.filter = (obj: object, predicate: (v: [string, string]) => boolean) =>
  Object.fromEntries(Object.entries(obj).filter(predicate));

export interface CollectionOpts<T, TApi> {
  isPartial?: boolean;
  isCached?: boolean;
  deserializer?: (data: TApi) => T;
  serializer?: (data: T) => unknown;
}

export interface SubResourceOpts<T, TApi> {
  deserializer?: (data: TApi) => T;
  serializer?: (data: T) => TApi;
}

type ParamValue = string | number | boolean | undefined | ParamValue[];

export interface ListQueryParams {
  range?: number[];
  ordering?: string;

  [Property: string]: ParamValue;
}

interface ListQueryHeaders extends AxiosHeaders {
  "entity-range"?: string;
}

interface RequestOpts<T, TApi, R = T, RApi = TApi> {
  deserializer?: (data: RApi) => R;
  serializer?: (data: T) => TApi;
  isCached?: boolean;
  isPartial?: boolean; // Will be deprecated, leave this to specific typing and serialization/deserialization
}

interface MutationOpts<T, TApi, R = T, RApi = TApi> {
  method: "post" | "put" | "patch";
  path: string;
  data: T,
  deserializer: (data: RApi) => R;
  serializer: (data: T) => TApi;
  isCached?: boolean;
  isPartial?: boolean; // Will be deprecated, leave this to specific typing and serialization/deserialization
}

export class ListResult<T> extends Array<T> {
  constructor(request, items) {
    super(...(items || []));
    this.request = request;
    this.terms = request.terms;
  }

  count(): number {
    let range = null;
    if (this.request.count) {
      return this.request.count;
    }
    try {
      range = this.request.headers["content-range"];
    } catch {
      return this.length;
    }
    if (!range) {
      return this.length;
    }
    return Number(range.split("/")[1]);
  }
}

interface ApiClientOpts {
  baseURL: string;
}

const AuthenticationInterceptor = (req: InternalAxiosRequestConfig) => {
  if (req.meta?.disableAuthentication) {
    return req
  }
  const {token} = storeToRefs(useSession());
  if (token.value) {
    req.headers.Authorization = `Bearer ${token.value}`;
  }
  return req;
}

const waitForNewSession = async () => {
  const session = useSession();
  const {isReady} = storeToRefs(session);
  Logger.info("🚒 Session expired, refreshing...");
  session.tokenInvalid();
  return until(isReady).toBe(true, {timeout: 5000});
}

const RetryOnErrorInterceptor = async (instance: AxiosInstance, err: AxiosError) => {
  if (!err.config) {
    return Promise.reject(err);
  }
  if (err.response?.status === 401 && (err.config.meta.authRetry ?? 0 > MAX_AUTH_RETRIES)) {
    // Max auth retries reached
    return Promise.reject(new ApiError(err));
  }

  if (err.response?.status === 400) {
    // Client error: validation
    return Promise.reject(new ApiValidationError(err));
  }

  if (err.response?.status === 401) {
    // Client error: incorrect authorization
    await waitForNewSession();
    err.config.meta.authRetry = (err.config.meta?.authRetry ?? 0) + 1;
    return instance(err.config ?? {});
  }
  return Promise.reject(new ApiError(err));
}

export const createApiClient = (opts: ApiClientOpts) => {
  const client = setupCache(axios.create({
    baseURL: opts.baseURL,
    headers: {
      "Content-Type": 'application/json',
    },
    meta: {},
  }))

  client.interceptors.request.use(AuthenticationInterceptor);
  client.interceptors.response.use(
    undefined,
    (err) => RetryOnErrorInterceptor(client, err)
  )
  return client;
}

export type ApiServiceOpts = object;

export class ApiService {
  public request: AxiosCacheInstance; // @deprecated
  public client: AxiosCacheInstance;

  constructor(url: string, options?: ApiServiceOpts) {
    this.client = createApiClient({
      baseURL: url,
    });
    this.request = this.client;
    this.setup();
  }

  setup() {
    throw new Error("Not implemented");
  }

  async mutate<T, TApi, R = T, RApi = TApi>(opts: MutationOpts<T, TApi, R, RApi>) {
    const result = await this.client.request<RApi, TApi>({
      method: opts.method,
      url: opts.path,
      data: opts.serializer(opts.data, opts.isPartial),
    });
    return opts.deserializer(result.data);
  }
}


export class Service extends ApiService {
  // Service and LegacyApiService will be deprecated. Use composed API instead (see @/app/api.ts)
  async list<R>(path, params, headers, cache = true) {
    return this.request
      .get<unknown, R>(path, {
        id: path,
        params,
        headers,
        cache,
      })
      .then((r) => r.data);
  }

  async get<R>(path: string, params = {}, cache = true): Promise<R> {
    return this.request
      .get(path, {
        id: path,
        params,
        cache,
      })
      .then((r) => r.data as R);
  }

  async post<T, R>(path, params) {
    return this.request
      .post<T, R>(path, params, {
        cache: {
          update: {
            [path]: "delete",
          },
        },
      })
      .then((r) => r.data);
  }

  async del(path) {
    return this.request.delete(path).then((r) => r.data);
  }

  async put(path, params) {
    return this.request
      .put(path, params, {
        cache: {
          update: {
            [path]: "delete",
            [path.split("/").slice(0, -1).join("/")]: "delete",
          },
        },
      })
      .then((r) => r.data);
  }

  async patch(path, params) {
    return this.request.patch(path, params).then((r) => r.data);
  }

}

const useSerializers = (options) => ({
  serialize(data, isPartial = false) {
    if (options.serializer) {
      return options.serializer(data, isPartial);
    }
    return data;
  },
  deserialize(data) {
    if (options.deserializer) {
      return options.deserializer(data);
    }
    return data;
  },
});

export const Resource = <T extends Model, TApi extends Model>(
  service: Service,
  path,
  options: CollectionOpts<T, TApi> = {},
) => ({
  ...useSerializers(options),
  _mounts: {} as Record<string, { options: CollectionOpts<T, TApi> }>,
  composePath(rid: string | undefined, action: string | undefined = undefined) {
    let result = path;
    if (rid) {
      result += `/${rid}`;
    }
    if (action) {
      result += `/${action}`;
    }
    return result;
  },
  get(rid: string | null, params = {}): Promise<T> {
    return service
      .get(this.composePath(rid), params, options.isCached)
      .then(this.deserialize);
  },
  put(rid, params) {
    return service
      .put(this.composePath(rid), this.serialize(params))
      .then(this.deserialize);
  },
  async patch(rid: T["id"] | undefined, params: Partial<T>): Promise<T> {
    const data = await service
      .patch(this.composePath(rid), this.serialize(params, true));
    return this.deserialize(data);
  },
  del() {
    return service.del(path);
  },
  sub<R>(rid: T["id"], action: string, params = {}): Promise<R> {
    return service.get<R>(this.composePath(rid, action), params) as Promise<R>;
  },
  mount(name, options) {
    this._mounts[name] = {
      options,
    };
  },
});

export const Collection = <T extends Model, TApi extends Model>(
  service: Service,
  path: string,
  options: CollectionOpts = {},
) => ({
  ...Resource<T>(service, path, options),
  list(params: ListQueryParams = {}): Promise<ListResult<T>> {
    const headers: ListQueryHeaders = new AxiosHeaders();
    if (params.range) {
      const range = params.range;
      let rangeHeader = `entities=${range.shift()}`;
      if (range.length > 0) {
        rangeHeader += `-${range.shift()}`;
      } else {
        rangeHeader += "-";
      }
      headers["entity-range"] = rangeHeader;
    }
    return service
      .list(path, params, headers, options.isCached)
      .then(
        (r) => new ListResult<T>(r, (r.results || r).map(this.deserialize)),
      );
  },
  del(rid) {
    return service.del(`${path}/${rid}`);
  },
  action(rid: T["id"], action: string, params = {}) {
    return service.put(`${path}/${rid}/${action}`, params).then((v) => {
      service.request.storage.remove(path);
      return this.deserialize(v);
    });
  },
  sub<R>(rid: T["id"], action: string, params = {}) {
    if (this._mounts[action]) {
      const spec = this._mounts[action];
      return Collection(service, `${path}/${rid}/${action}`, spec.options);
    }
    return service.get<R>(`${path}/${rid}/${action}`, params, options.isCached);
  },
  subResource<RApi, R>(rid: T["id"], action: string, params = {}, sub_options: SubResourceOpts<R, RApi> = {}): Promise<R> {
    const transformers = useSerializers(sub_options);
    return service.get<RApi>(`${path}/${rid}/${action}`, params, options.isCached).then(r => transformers.deserialize(r));
  },
  terms(params) {
    return service.get(`${path}/terms`, params);
  },
  async create<MT = T, MTApi = TApi, MR = MT, MRApi = MTApi>(params: MT, options: RequestOpts<MT, MTApi, MR, MRApi> = {}): Promise<MR> {
    return service.mutate<MT, MTApi, MR, MRApi>({
      method: "post",
      path,
      serializer: options.serializer ?? this.serialize,
      deserializer: options.deserializer ?? this.deserialize,
      data: params,
      isPartial: options.isPartial,
    })
  },
});

export const GenericCollection = <T, TApi>(
  service: Service,
  path: string,
  options: CollectionOpts<T, TApi> = {},
) => {
  return {
    ...useSerializers(options),
    async list(params: ListQueryParams = {}) {
      const r = await service.list<TApi>(path, params, {}, options.isCached);
      return new ListResult<T>(r, (r.results || r).map(this.deserialize));
    }
  }
}

export class LegacyApiService extends Service {
  // Service and LegacyApiService will be deprecated. Use composed API instead (see @/app/api.ts)

  setup() {
    this.me = Resource(this, "/me");
    this.setupIntents = Collection<SetupIntent>(this, "me/setup_intents", {
      isCached: false,
    });
    this.uploads = Collection(this, "/uploads");
    this.payments = Collection(this, "/me/payments", {isCached: false});
    this.paymentMethods = Collection(this, "/me/payment_methods");
    this.payouts = Collection(this, "/me/payouts", {isCached: false});
    this.chats = Collection(this, "/chatter/chats", {
      ...ChatIO,
    });
    this.chats.mount("messages", {
      ...MessageIO,
    });

    this.tests = {
      authError: () => this.get("/_tests/auth_error"),
    };
  }
}

export default {
  install(app, options) {
    const api = new LegacyApiService(options.apiUrl);
    this.c = api;
    app.$api = this;
    app.config.globalProperties.$api = this;
    app.provide("api", this);
    app.provide(ApiInjectionKey, this);
  },
};

export interface IApi {
  c: {
    setupIntents: ReturnType<typeof Collection<SetupIntent>>;
    bookings: ReturnType<typeof Collection<IBooking>>;
    paymentMethods: ReturnType<typeof Collection<IPaymentMethod>>;
    chats: ReturnType<typeof Collection<Conversation>>;
    payments: ReturnType<typeof Collection<Payment>>;
    payouts: ReturnType<typeof Collection<Payout>>;
  };
}

export function useApi(): IApi {
  const result = inject(ApiInjectionKey);
  if (!result) throw "Misconfigured, no API";
  return result;
}
