<template>
  <AutoComplete :context="autoCompleteContext">
    <template #prefix>
      <Icon
        class="formkit-prefix-icon formkit-icon"
        name="outlined/location_on"
        variant="inherit"
      />
    </template>
  </AutoComplete>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import AutoComplete from "@/form/Autocomplete.vue";

import { ListFilter } from "@/rental/api";

const api = inject("api");

throw new Error("Broken", "This component is using the centralized api, should use composed api instead.");

const props = defineProps({
  context: {
    type: Object,
    default: () => {},
  },
});

const filters = ref(props.context.filters || {});

const autoCompleteContext = computed(() => {
  return {
    ...props.context,
    searchHandler: search,
    lookupHandler: (term) => ({ label: term }),
  };
});

async function search(term) {
  // TODO: refactor this to composed API
  const result = await api.c.listings.terms({
    range: [0, 1],
    filter_by: ListFilter({
      ...filters.value,
      cityStateLike: term.value,
    }),
    include_terms: "pickup_location__city_state_long",
  });

  if (!result.pickup_location__city_state_long) {
    return [];
  }

  return result.pickup_location__city_state_long.map((el) => ({
    label: el,
    value: el,
  }));
}
</script>

<style lang="scss" scoped></style>
