import {defineStore, storeToRefs} from 'pinia';
import {computed, markRaw, ref, toRaw, toRefs, watch} from 'vue';
import {type ApiCollectionModel, useApiResource} from "@/app/api";
import {IoModel, useCollectionDeserializer, useSerializer} from "@/app/io";
import {useResource} from "@/helpers/store";
import {type SignInOpts, type SignUpOpts, useAuth} from "@/app/auth";
import {useLocalStorage} from "@vueuse/core";
import {Logger} from '@/app/logger';
import {useEventBus} from "@/app/eventBus";
import {useApi} from "@/plugins/Api";
import {SessionReady, SessionSignedIn, SessionSignedOut} from "@/app/events";
import {ActorDeserializer, Avatar, IActor} from "@/app/models/actor";
import {PhoneNumber} from "@/app/models/address";
import type {CollectionModel} from "@/store/base/models";
import {ApiError, InvalidActionError} from "@/errors";
import type {AuthRole} from "@/app/vue-router";

export interface ApiActor extends ApiCollectionModel {
  id: string;
  first_name?: string;
  last_name?: string;
  avatar?: Avatar;
  num_listings?: number;
  rating?: number;
  reviews?: number;
}

interface ApiUser extends ApiActor {
  username: string;
  email: string;
  has_new_messages: boolean;
  avatar_id?: string;
  phone_number?: string;
}

export interface Actor extends CollectionModel {
  id: string;
  firstName: string;
  lastName: string;
  avatar: Avatar;
  numListings: number;
  reviews: number;
  rating: number;
  fullName: string;
  initials: string;
  avatarUrl?: string;
  hasAvatar: boolean;
}

interface User extends IActor {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar: Avatar;
  numListings: number;
  hasNewMessages: boolean;
  phoneNumber?: PhoneNumber;

  fullName: string;
  avatarId?: string | null;
  avatarUrl?: string;
  initials: string;
}

type UserMutable = Pick<User, "firstName" | "lastName" | "avatarId" | "phoneNumber">
type ApiUserMutable = Pick<ApiUser, "first_name" | "last_name" | "avatar_id" | "phone_number">

export const {
  deserializer: actorDeserializer,
  schema: actorSchema,
  ext: actorExtension
} = ActorDeserializer;

const userDeserializer = useCollectionDeserializer<ApiUser, User, "fullName" | "avatarUrl" | "initials" | "hasAvatar">({
  ...actorSchema,
  username: "username",
  email: "email",
  hasNewMessages: "has_new_messages",
  avatarId: "avatar_id",
  phoneNumber: (obj) => obj.phone_number ? PhoneNumber.fromString(obj.phone_number) : undefined,
}, actorExtension)

const userSerializer = useSerializer<UserMutable, ApiUserMutable>({
  first_name: "firstName",
  last_name: "lastName",
  avatar_id: "avatarId",
  phone_number: (obj) => obj.phoneNumber?.serialize(),
})

export const useSession = defineStore('Session', () => {
  const token = useLocalStorage<string | null>("prettyshell:token", null)
  const auth = useAuth();
  const bus = useEventBus();
  const api = useApi();
  const isReady = ref(false);
  const syncing = ref(false);
  const authRole = ref<AuthRole>("visitor");

  const isAuthenticated = computed(() => isReady.value && !!token.value);

  const apiResource = useApiResource<User, ApiUser>("me", {
    deserializer: userDeserializer.deserializer,
    serializer: userSerializer.serializer,
    isCached: false,
  })

  const resource = useResource<User>(async () => {
    const item = await apiResource.get(null);
    Logger.log("🙋 User: ", item);
    return item;
  })

  const user = computed<User | undefined>(() => {
    if (resource.item.value) {
      return resource.item.value
    }
  });
  const actor = computed<Actor | undefined>(() => {
    if (user.value) {
      const model = new IoModel(user.value);
      const keys = Object.keys({...actorSchema, ...actorExtension}) as (keyof User)[];
      return model.pick<Actor>(keys);
    }
  });

  const signUp = async (params: SignUpOpts) => {
    await auth.signUp(params)
  };

  const resendSignUpCode = async (email: string) => {
    return await auth.resendVerificationEmail(email);
  }

  const verifyEmail = async (email: string, code: string) => {
    return await auth.verifyEmail(email, code);
  };

  const signIn = async (options: SignInOpts) => {
    if (isAuthenticated.value) {
      throw new InvalidActionError('Already signed in');
    }
    isReady.value = false;
    await auth.signIn(options);
    await syncToken();
  }

  const signInGoogle = async () => {
    await auth.signInGoogle();
  }

  const signInApple = async () => {
    await auth.signInApple();
  }

  const signOut = async (cb: (() => void) | undefined) => {
    isReady.value = false;
    await auth.signOut();
    token.value = null;
    resource.item.value = null;
    isReady.value = true;
    if (cb) cb();
  }

  const syncToken = async () => {
    const newSession = await auth.acquireSession();
    if (newSession.type === "authenticated" && newSession.result?.tokens) {
      token.value = newSession.result.tokens.accessToken.toString();
      return
    }
    syncing.value = false;
    isReady.value = true;
  }

  const syncSession = async () => {
    if (isReady.value || syncing.value) {
      // Skip syncs outside any entrypoint that should activate a sync
      return
    }
    syncing.value = true;

    if (!token.value) {
      return syncToken();
    }
    try {
      await resource.sync();
    } catch (err) {
      if (err instanceof ApiError && err.error.status === 401) {
        return syncToken();
      }
    }
    isReady.value = true;
    syncing.value = false;
  }

  const tokenInvalid = () => {
    // entrypoint to a sync
    isReady.value = false;
    token.value = null;
  }

  const updateUser = async (values: Partial<UserMutable>) => {
    if (!resource.item.value) {
      Logger.error("No user to update");
      return;
    }
    const result = await apiResource.patch(undefined, values);
    resource.update(result);
    return result;
  }

  const deleteUser = async () => {
    await apiResource.del();
  }

  watch(token, syncSession, {
    immediate: true,
  });

  watch(isReady, (newReady) => {
    if (newReady) {
      bus.publish(SessionReady({
        authenticated: isAuthenticated.value,
      }));

      if (isAuthenticated.value) {
        bus.publish(SessionSignedIn());
      } else {
        bus.publish(SessionSignedOut());
      }
    }
  });

  watch(isAuthenticated, (newAuthenticated) => {
    if (newAuthenticated) {
      authRole.value = "user";
    } else {
      authRole.value = "visitor";
    }
  })
  return {
    actor,
    user,
    token,
    isAuthenticated,
    isReady,
    authRole,
    signUp,
    resendSignUpCode,
    signIn,
    signInGoogle,
    signInApple,
    signOut,
    tokenInvalid,
    verifyEmail,
    updateUser,
    deleteUser,
  };
});

export const useCurrentUser = () => {
  const { user } = storeToRefs(useSession());
  return user;
}

export const getCurrentUser = () => {
  const { user } = storeToRefs(useSession());
  if (!user.value) {
    throw "No user";
  }
  return user.value;
}

export const useCurrentActor = () => {
  const session = useSession();
  const {actor} = storeToRefs(session);
  return actor;
}

export const getCurrentActor = () => {
  const session = useSession();
  const {actor} = storeToRefs(session);
  if (actor.value === null) {
    throw "No user";
  }
  return actor.value;
}
