import {Ref, ref} from "vue";
import {until} from "@vueuse/core";

export const useLoading = (cb: () => Promise<void>) => {
  const isLoading = ref(false);
  const trigger = async () => {
    isLoading.value = true;
    await cb();
    isLoading.value = false;
  };

  return {
    trigger,
    isLoading,
  };
};

export const asyncRef = async <T>(r: Ref<T>) => {
  await until(r).not.toBeUndefined();
  return r;
};
