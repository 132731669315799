<template>
  <div :class="classes">
    <Avatar :size="avatarSize" :user="person"/>
    <div class="details">
      <div class="person-title">
        <span class="fullname">{{ person.fullName }}</span>
        <RatingSmall
          v-if="showRating && person.reviews > 0"
          :reviews="person.reviews"
          :value="person.rating"
        />
      </div>

      <template v-if="!hideJoinedDate">
        <p
          v-if="size === 'medium'"
        >
          Member since {{ helpers.toThen(person.createdAt) }} •
          <span class="num-listings">{{ person.numListings }} active listings</span>
        </p>
        <p
          v-else-if="size === 'small'"
        >
          {{ helpers.dateLocalFormat(person.createdAt) }}
        </p>
      </template>
      <slot name="details-extra"/>
    </div>
    <slot name="side">
      <div
        v-if="showContact"
        class="card-actions"
      >
        <PsButton
          :disabled="isDisabledContactAndMessageButtons"
          color="secondary"
          icon="outlined/forum"
          @click="gotoChat"
        >
          Send message
        </PsButton>
      </div>
    </slot>
  </div>
</template>
<script lang="ts" setup>
import {useRouter} from "vue-router";

import Avatar from "@/elements/Avatar.vue";

import {useHelpers} from "@/plugins/Form";
import {useBookings} from "@/rental/stores/bookings";
import {useConversationStore} from "@/store/chatter/chats";
import type {Actor} from '@/app/models/actor';
import RatingSmall from "@/components/RatingSmall.vue";
import {useCurrentActor} from "@/app/stores/session";
import {computed, toRefs} from "vue";
import {useClasses} from "@/composables/styling";

const router = useRouter();
const currentUser = useCurrentActor();
const helpers = useHelpers();
const convoStore = useConversationStore();
const bookingStore = useBookings();

const props = withDefaults(
  defineProps<{
    person: Actor;
    size?: "small" | "medium";
    showRating?: boolean;
    showContact?: boolean;
    hideJoinedDate?: boolean;
    bookingId?: string;
    bookingStatus?: string;
    bookingChatId?: string;
  }>(),
  {
    size: "medium",
    showRating: false,
    showContact: false,
    bookingId: undefined,
    bookingStatus: undefined,
    bookingChatId: undefined,
  },
);
const {size} = toRefs(props);

const classes = useClasses(props, {
  size: {
    small: "is-small",
    medium: "is-medium",
    large: "is-large",
  }
}, ['person-card']);

const isDisabledContactAndMessageButtons: boolean =
  props.person.id === currentUser.value?.id ||
  (!props.bookingChatId &&
    !!props.bookingId &&
    props.bookingStatus === "expired");

const avatarSize = computed(() => size.value);

async function gotoChat() {
  let chatId;

  // If on a booking, we can use the booking chat id
  if (props.bookingChatId) {
    chatId = props.bookingChatId;
    // If on a booking, but no chat id yet, create one (EXCEPT for expired bookings)
  } else if (props.bookingStatus !== "expired" && props.bookingId) {
    const updatedBooking = await bookingStore.createBookingChat(
      props.bookingId,
      props.person.id,
    );
    chatId = updatedBooking.chat;
  } else {
    const convo = await convoStore.createConversation(props.person.id);
    chatId = convo.id;
  }

  if (!chatId) return;

  router.push({
    name: "chat",
    params: {
      chatId,
    },
  });
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
@import '@/styles/mixins.scss';

.num-listings {
  color: $gray-muted;
}

.person-card {
  @include horizontal-list($gap: var(--gap-extra-small));
  justify-content: flex-start;
  margin: 1em 0;

  .details {
    @include vertical-list($gap: 0);
    flex-grow: 1;

    .person-title {
      @include horizontal-list($gap: var(--gap-extra-small));
      justify-content: flex-start;
      font-weight: 600;
    }
  }
}
</style>
