import {computed, type ComputedRef, isRef, ref, type Ref, shallowRef, triggerRef, watch} from "vue";
import {Logger} from '@/app/logger';
import { bus } from '@/app/eventBus';
import {createEventDefinition} from "ts-bus";
import {useArrayFilter} from "@vueuse/core";
import _ from "lodash";
import {ObjectNotFoundError} from "@/errors";

export interface Model extends Record<string, unknown> {
  readonly id: string;
}

export interface useCollectionOpts {
  domain?: string;
}

export const createCollectionEvents = <T extends Model>(domain: string) => {
  return {
    itemUpdated: createEventDefinition<{instance: T}>()(`${domain}.collection.update`)
  }
}

class Collection<T extends Model> extends Array<T> {
  getById(id: T["id"]): T | undefined {
    return this.find((el) => el.id === id);
  }

  getByKey(key: keyof T, value: T[typeof key]): T | undefined {
    return this.find((el) => el[key] === value);
  }

  updateById(id: T["id"], item: Partial<T>) {
    const instance = this.getById(id);
    if (instance) {
      Object.assign(instance, item);
      Logger.log("💾 Collection.update", id);
      return true;
    }
    return false;
  }

  removeById(id: T["id"]) {
    const ix = this.findIndex((el) => el.id === id);
    if (ix > -1) this.splice(ix, 1);
    return ix;
  }

  update(items: T[]) {
    const result = {
      updated: 0,
      added: 0,
    };
    for (const item of items) {
      if (this.updateById(item.id, item)) {
        result.updated++;
      } else {
        this.push(item);
        result.added++;
      }
    }
    Logger.log("💾 Collection.update", result);
    return result;
  }
}

export const useGetByKey = <T extends Model>(
  key: keyof T,
  collection: Ref<Collection<T>>,
) => {
  return (v: T[typeof key]) => collection.value.find((el) => el[key] === v);
};

export const useResource = <T extends Model>(getter: () => Promise<T>) => {
  const item = ref<T | null>(null);
  const loading = ref(true);
  const sync = async () => {
    loading.value = true;
    item.value = await getter();
    loading.value = false;
  };
  return {
    item,
    sync,
    loading,
    update: (obj: T) => {
      if (item.value) {
        item.value = Object.assign(item.value, obj) as T;
      } else {
        item.value = obj as T;
      }
    }
   }
}

export const useCollection = <T extends Model>(getter: () => Promise<T[]>, opts: useCollectionOpts = {}) => {
  const events = createCollectionEvents<T>(opts.domain ?? "app");
  const items = ref<Collection<T>>(new Collection<T>());
  const reset = () => {
    items.value = new Collection<T>();
  };
  const arr = computed<T[]>(() => new Array(...items.value));

  const loading = ref(true);
  const initialized = ref(false);
  const sync = async (force = false) => {
    if (!force && loading.value && initialized.value) return;
    initialized.value = true;
    loading.value = true;
    const result = await getter();

    items.value.update(result);
    triggerRef(items);
    loading.value = false;
  };

  const isEmpty = computed(() => items.value.length === 0);

  const ensureById = async (id: T["id"], getter: (id: T["id"]) => Promise<T>): Promise<T> => {
    let result = items.value.getById(id);
    if (!result) {
      result = await getter(id);
      items.value.push(result);
    }
    result = items.value.getById(id);
    if (!result) {
      throw "Could not find object";
    }
    return result;
  };
  const updateById = (id: T["id"], item: Partial<T>) => {
    items.value.updateById(id, item);
    triggerRef(items);
  }
  const add = (obj: T) => {
    items.value.push(obj);
    triggerRef(items);
  }
  const removeById = (id: T["id"]) => {
    items.value.removeById(id);
    triggerRef(items);
  }
  const isLoading = computed<boolean>(() => loading.value);
  const useById = (id: T["id"]) => computed(() => items.value.getById(id));
  const withAction = <P extends unknown[]>(actioner: (id: T["id"], ...params: P) => Promise<T>) => async (id: T["id"], ...params: P) => {
    const item = await actioner(id, ...params);
    updateById(id, item);
    return item;
  }
  return {
    items,
    sync,
    loading,
    getById: (id: T["id"]): T => {
      const result = items.value.getById(id);
      if (result === undefined) {
        throw new ObjectNotFoundError(`Instance not found (id: ${id})`);
      }
      return result
    },
    hasId (id: T["id"]) {
      try {
        return !!this.getById(id);
      } catch (err) {
        if (err instanceof ObjectNotFoundError) return false;
        throw err;
      }
    },
    getByFiltered: (filter: (obj: T) => boolean) => items.value.filter(filter),
    updateById,
    useById,
    removeById,
    add,
    useFiltered: (cb: (obj: T) => boolean) => useArrayFilter<T>(arr, cb),
    reset: reset,
    $reset: reset,
    ensureById,
    withAction,
    isEmpty,
    isLoading,
    events,
  };
};
