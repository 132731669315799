import type * as ioModels from "@/io/account";
import type { CollectionModel } from "@/store/base/models";
import type {ApiCollectionModel} from "@/app/api";
import {useCollectionDeserializer} from "@/app/io";

export interface ApiActor extends ApiCollectionModel {
  id: string;
  first_name?: string;
  last_name?: string;
  avatar?: Avatar;
  num_listings?: number;
  rating?: number;
  reviews?: number;
}

export interface Avatar {
  id: string;
  url?: string;
}

export interface IActor extends CollectionModel {
  id: string;
  firstName: string;
  lastName: string;
  avatar: Avatar;
  numListings: number;
  reviews: number;
  rating: number;
  fullName: string;
  initials: string;
  avatarUrl?: string;
  hasAvatar: boolean;
}

export type Actor = IActor;

export const ActorDeserializer = useCollectionDeserializer<ApiActor, IActor, "fullName" | "avatarUrl" | "initials" | "hasAvatar">({
  firstName: "first_name",
  lastName: "last_name",
  avatar: "avatar",
  numListings: "num_listings",
  reviews: "reviews",
  rating: (obj) => (obj.rating ?? 0) / 10,
}, {
  fullName: (actor) => `${actor.firstName} ${actor.lastName}`,
  avatarUrl: (actor) => actor.avatar?.url,
  initials: (actor) => [actor.firstName, actor.lastName].map(s => s?.slice(0, 1)).join("").toUpperCase(),
  hasAvatar: (actor) => actor.avatar?.url !== undefined,
})

