<template>
  <ListingSection
    ref="reviewsSectionRef"
    title="Reviews"
  >
    <p>This is what other people think.</p>
    <div
      v-if="listing.numReviews > 0"
      class="is-flex-direction-column is-flex"
    >
      <div class="rating-summary">
        <div class="rating-number">
          {{ listing.rating }}
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
        >
          <div>
            <FormKit
              :read-only="true"
              :value="Number(listing.rating)"
              type="rating"
            />
          </div>
          <p class="help">
            Average of {{ listing.numReviews }} review{{ listing.numReviews === 1 ? '' : 's' }}
          </p>
        </div>
      </div>
      <div class="is-flex is-flex-direction-column">
        <div
          v-for="(review, ix) in reviews"
          :key="review.id"
        >
          <PersonCard
            :person="review.author"
            hide-joined-date
            size="medium"
          >
            <template #details-extra>
              <p>{{ helpers.dateLocalFormat(review.createdAt)}}</p>
            </template>
            <template #side>
              <div class="is-flex-grow-1 is-justify-content-flex-end is-flex">
                <Rating
                  :show-reviews="false"
                  :value="review.rating"
                />
              </div>
            </template>
          </PersonCard>
          <p
            v-if="review.comment"
            class="my-2"
          >
            {{ review.comment }}
          </p>
          <p
            v-else
            class="my-2 has-text-grey-light is-italic"
          >
            {{ t('review.no-review') }}
          </p>
          <hr v-if="ix + 1 !== listing.numReviews">
        </div>
      </div>
    </div>
    <div
      v-else
      class="has-text-grey-light is-flex is-align-items-center"
    >
      <Icon
        class="mr-2"
        name="base/star"
        size="medium"
        variant="inherit"
      />
      <span>No reviews yet</span>
    </div>
  </ListingSection>
</template>

<script lang="ts" setup>
import PersonCard from "@/components/people/PersonCard.vue";
import ListingSection from "@/components/rental/listing/ListingSection.vue";
import Rating from "@/components/Rating.vue";
import {useListing, usePublicListings} from "@/rental/stores/listings";
import {toRefs} from "vue";
import {asyncRef} from "@/composables/loaders";
import {helpers} from "../../plugins/Form";

const props = defineProps<{
  listingId: string,
}>()

const store = usePublicListings();

const { listingId } = toRefs(props);

const listing = await asyncRef(useListing(listingId));
const reviews = await asyncRef(store.useReviews(listingId));
</script>
<style scoped>
.rating-summary {
  &{
    display: flex;
    gap: var(--gap-extra-small);
  }
  .rating-number {
    font-family: 'RobotoSlab', Helvetica;
    font-size: 3em;
    font-weight: 700;
  }
}
</style>
