<template>
  <div class="listing-editor-container">
    <aside class="is-hidden-mobile">
      <Workflow :current-step="currentStepNumber" :progressed-step="currentProgressedNumber" :steps="evaluatedSteps"
        is-numbered @step-changed="onStepChanged" />
    </aside>
    <div class="is-hidden-tablet">
      <Workflow :current-step="currentStepNumber" :progressed-step="currentProgressedNumber" :steps="evaluatedSteps"
        is-numbered @step-changed="onStepChanged" />
    </div>
    <div class="listing-editor-content">
      <FormKit :id="FORM_ID" v-slot="{ state: { valid: formValid } }" v-model="formData" :actions="false"
        :config="{ validationVisibility: 'dirty' }" dirty-behavior="compare" preserve type="form">
        <div class="level mb-4">
          <div class="level-left" />
          <div class="level-right">
            <div class="level-item">
              <PsButton ref="saveDraft" :disabled="listing?.status === 'active' || !isValid" color="secondary"
                @click.prevent="saveAndGotoListing">
                Save as draft
              </PsButton>
            </div>
            <div class="level-item">
              <PsButton v-if="listing?.status !== 'active'" :disabled="!canPublish" @click.prevent="publish">
                Publish
              </PsButton>
              <PsButton v-if="listing?.status === 'active'" :disabled="!canPublish" @click.prevent="saveAndGotoListing">
                Save
              </PsButton>
            </div>
          </div>
        </div>

        <ListingEditorDetails v-show="currentStep.name === 'details'" />
        <ListingEditorPhotos v-show="currentStep.name === 'photos'" />
        <ListingEditorPricing v-show="currentStep.name === 'pricing'" />
        <ListingEditorAvailability v-show="currentStep.name === 'availability'" />
        <ListingEditorLocation v-show="currentStep.name === 'location'" />
        <ListingEditorProtection v-show="currentStep.name === 'protection'" />
        <ListingEditorPublish v-show="currentStep.name === 'publish'" :steps="evaluatedSteps"
          @step-changed="onStepChanged" />

        <div class="level my-6">
          <div class="level-left">
            <div class="level-item">
              <PsButton v-if="isFirstStep" color="secondary" @click.prevent="cancel">
                Cancel
              </PsButton>
              <PsButton v-else color="secondary" @click.prevent="previousStep">
                Back
              </PsButton>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <PsButton v-if="!isLastStep" :disabled="!isValid" :isLoading="isLoading" @click.prevent="saveAndContinue" v-test-id="'button-save-and-continue'">
                Save & continue
              </PsButton>
              <PsButton v-else-if="initialData.status === 'active'" :isLoading="isLoading" @click.prevent="saveAndGotoListing">
                Save
              </PsButton>
              <PsButton v-else :class="isLoading ? 'is-loading' : null" :disabled="!canPublish"
                @click.prevent="publish" v-test-id="'button-publish'">
                Publish
              </PsButton>
            </div>
          </div>
        </div>

        <ListingEditorModal v-model="stepModalActive" :listing-status="listing?.status" :save-disabled="!isValid"
          @save="onSaveAndGotoStep" @discard="onDiscardStep" />

        <ListingEditorModal v-model="cancelModalActive" :listing-status="listing?.status" :save-disabled="!isValid"
          @save="save" @discard="discard" />

      </FormKit>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useModalActions } from "@/composables/modal";
import { computed, ref, toValue, unref, watch } from "vue";
import { useFormKitContextById } from "@formkit/vue";
import { useRouter } from "vue-router";
import { type PrivateListing, usePrivateListings } from "@/rental/stores/listings";
import { asyncComputed, useThrottle, useThrottleFn } from "@vueuse/core";
import STEPS from '@/flows/createListing/steps';
import type { EvaluatedStep, Step } from '@/flows/createListing/steps';
import ListingEditorDetails from "@/rental/components/ListingEditorDetails.vue";
import ListingEditorPhotos from "@/rental/components/ListingEditorPhotos.vue";
import ListingEditorModal from "@/rental/components/ListingEditorModal.vue";

import { useSteps } from "@/app/components/workflow/composables";
import type { StepChangedEvent, StepItem } from "@/app/components/workflow/types";
import { Logger } from "@/app/logger";
import { useLoading } from "@/composables/loaders";
import ListingEditorPricing from "@/rental/components/ListingEditorPricing.vue";
import ListingEditorAvailability from "@/rental/components/ListingEditorAvailability.vue";
import ListingEditorLocation from "@/rental/components/ListingEditorLocation.vue";
import ListingEditorProtection from "@/rental/components/ListingEditorProtection.vue";
import ListingEditorPublish from "@/rental/components/ListingEditorPublish.vue";
import Workflow from "@/app/components/workflow/Workflow.vue";

const FORM_ID = "form";

const props = withDefaults(defineProps<{
  listingId?: PrivateListing['id'] | undefined,
  initialData?: Partial<PrivateListing>,
  initialProgressedStep?: number,
  initialStep?: number,
}>(), {
  initialStep: 1,
  initialProgressedStep: 1,
  initialData: () => ({
    pickupDropOffTimeRange: ["10:00", "18:00"],
  }),
  listingId: undefined,
})

const cancelModalActive = ref(false);
const stepModalActive = ref(false);
const form = useFormKitContextById(FORM_ID);
const isDirty = computed<boolean>(() => form.value?.state.dirty ?? false);
const router = useRouter();
const listings = usePrivateListings();
const listing = asyncComputed<PrivateListing | undefined>(async () => props.listingId ? await listings.ensureById(props.listingId) : undefined)
const formData = ref<Partial<PrivateListing>>(props.initialData);
const isNew = computed<boolean>(() => props.listingId === undefined);

const [openCancelModal, closeCancelModal] = useModalActions(cancelModalActive);
const [openStepModal, closeStepModal] = useModalActions(stepModalActive);

const {
  nextStep, gotoStep, currentStep, isLastStep, isFirstStep,
  currentStepNumber, currentProgressedNumber, previousStep,
} = useSteps<Step>({
  steps: STEPS,
  initialStep: props.initialStep,
  initialProgressedStep: props.initialProgressedStep,
});

const { canPublish, isValid, stepsValid, currentEvaluatedStep, evaluatedSteps } = (() => {
  const _canPublish = ref(false);

  const _updateCanPublish = async (args) => {
    if (formData.value.id === undefined) return false;
    _canPublish.value = await listings.canPublish(formData.value.id);
  }

  const evaluatedSteps = computed<EvaluatedStep[]>(() => STEPS.map(
    (step) => ({
      ...step,
      isValid: step.isValid ? step.isValid(formData.value, _canPublish.value) : false
    })
  ))

  const currentEvaluatedStep = computed<EvaluatedStep>(() => evaluatedSteps.value[currentStepNumber.value - 1]);
  const isValid = computed<boolean>(() => currentEvaluatedStep.value.isValid);
  const stepsValid = computed<boolean>(() => evaluatedSteps.value.slice(0, -1).every(step => step.isValid));

  watch([formData, stepsValid], useThrottleFn(_updateCanPublish))

  return {
    canPublish: _canPublish,
    isValid, stepsValid, currentEvaluatedStep, evaluatedSteps
  }
})()
const discard = () => {
  if (listing.value !== undefined) {
    router.push({
      name: "listing",
      params: { id: listing.value.id },
    });
  } else {
    router.push({ name: "accountListings" });
  }
}

const upsert = async (patchData) => {
  const postData = {
    ...(patchData || formData.value),
  } as Partial<PrivateListing>;

  const result = formData.value?.id
    ? await listings.update(formData.value.id, postData)
    : await listings.create(postData);

  Object.assign(formData.value, structuredClone(result));
  return result;
}

const { isLoading, trigger: save } = useLoading(async () => {
  await upsert(currentStep.value.getPart(formData.value));
})

const saveAndContinue = async () => {
  if (!isDirty.value) {
    nextStep();
    return;
  }
  await save();
  nextStep();
}

const saveAndGotoListing = async () => {
  await save();
  const _listingId = formData.value.id as PrivateListing['id'];
  await router.push({
    name: "listing",
    params: { id: _listingId },
  });
}

const publish = async () => {
  await save()
  const _listingId = formData.value.id as PrivateListing['id'];
  await listings.publish(_listingId);
  await router.push({
    name: "listing",
    params: { id: _listingId },
  });
};

const { onDiscardStep, onStepChanged, onSaveAndGotoStep } = (() => {
  const _target = ref<number | undefined>(undefined);
  const _changeHandler = (event: StepChangedEvent) => {
    Logger.log("↩ onStepChanged: ", event, isDirty);
    if (isDirty.value) {
      // confirm action on changes
      _target.value = event.stepIndex;
      openStepModal();
    } else {
      gotoStep(event.stepIndex);
    }
  }

  const _saveHandler = async () => {
    await save();
    gotoStep(_target.value);
    closeStepModal();
  }
  return {
    onDiscardStep: () => {
      gotoStep(_target.value);
      closeStepModal();
    },
    onPreviousStep: () => { },
    onStepChanged: _changeHandler,
    onSaveAndGotoStep: _saveHandler,
  }
})()

const cancel = () => {
  if (isDirty) {
    openCancelModal();
  } else {
    discard();
  }
}

</script>
<style lang="scss" scoped>
@import '@/styles/mixins';

.listing-editor-container {
  display: flex;
  flex-direction: row;
  gap: 3em;
  margin-top: 2em;

  @include touch {
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
  }

  aside {
    min-width: 20em;

    @include touch {
      width: 100%;
    }
  }

  section {
    padding: 1em 0;
  }

  .listing-editor-content {
    flex-grow: 1;
  }

  .level-item {
    @include touch {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}
</style>
