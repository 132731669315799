import {camelCase, snakeCase} from "change-case";
import Formats from "@/helpers/formats";
import type {ApiCollectionModel} from "@/app/api";

type Model = {
  [key: string]: unknown;
};
export type GenericModel = Model;
const GenericObject = {} as Model;

class Missing extends Error {
}

type Deserializer<TIn = object, TOut extends Model = Model> = (serialized: TIn) => TOut;
type FieldDeserializer<TIn, TOut> =
  | ((obj: TIn) => TOut[keyof TOut])
  | keyof TIn;

export type Schema<TIn, TOut> = {
  [Property in keyof TOut]: FieldDeserializer<TIn, TOut>
}

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];
type OmitCollectionKeys<T> = Omit<T, "id" | "createdAt" | "updatedAt">;
type OmitExtension<T extends object, ExtKeys extends (keyof T)> = Omit<T, ExtKeys>;
export type Extension<T extends Model, ExtKeys extends (keyof T)> = {
  [K in ExtKeys]: (partial: T) => T[K]
};
type DeserializerOutput<TIn, TOut extends Model, ExtKeys extends (keyof TOut)> = {
  schema: Schema<TIn, OmitExtension<TOut, ExtKeys>>;
  deserializer: (serialized: TIn) => TOut;
  ext: Extension<TOut, ExtKeys>;
};

export const serialize = {
  date: (v: Date) => (typeof v === "string" ? v : v.toISOString().slice(0, 10)),
  cents_amount: (v: number) => v * 100,
};

export const deserialize = {
  date: (v: string): Date => Formats.parse(v).toDate(),
  cents_amount: (v: number) => v / 100,
};

export const camelizeObject = (obj: object) => {
  /* Returns a new object from `obj` where the keys are camel case */
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [camelCase(key), value]),
  );
};

export const snakeObject = (obj: object) => {
  /* Returns a new object from `obj` where the keys are snake case */
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [snakeCase(key), value]),
  );
};

export const useDeserializer = <TIn, TOut extends Model, ExtKeys extends (keyof TOut) = keyof object>(schema: Schema<TIn, OmitExtension<TOut, ExtKeys>>, ext?: Extension<TOut, ExtKeys>): DeserializerOutput<TIn, TOut, ExtKeys> => {
  return {
    deserializer: (serialized: TIn) => {
      const entries = Object.entries(schema) as Entries<Schema<TIn, TOut>>;
      const result = Object.fromEntries(
        entries.map(([key, field]) => {
          if (typeof field === "string") {
            const tInKey = field as keyof TIn
            return [key, serialized[tInKey]];
          }
          if (typeof field === "function") {
            return [key, field(serialized)];
          }
          return [key, field];
        }),
      ) as TOut;
      if (ext) {
        let key: keyof Extension<TOut, ExtKeys>;
        for (key in ext) {
          const getter = ext[key];
          Object.defineProperty(result, key, {
            get () {
              return getter(this)
            },
          });
        }
      }
      return result;
    },
    schema,
    ext: ext ?? {} as Extension<TOut, ExtKeys>,
  }
}

export const useCollectionDeserializer = <TIn extends ApiCollectionModel, TOut extends Model, ExtKeys extends (keyof OmitCollectionKeys<TOut>) = keyof object>(schema: Schema<TIn, OmitExtension<OmitCollectionKeys<TOut>, ExtKeys>>, ext: Extension<TOut, ExtKeys>) => {
  return useDeserializer<TIn, TOut, ExtKeys>({
    id: 'id',
    createdAt: (obj) => new Date(obj.created_at),
    updatedAt: (obj) => new Date(obj.updated_at),
    ...schema,
  } as Schema<TIn, OmitExtension<TOut, ExtKeys>>, ext);
}

export const useSerializer = <TIn extends Model, TOut extends Model>(schema: Schema<TIn, TOut>) => {
  return {
    serializer: (deserialized: TIn, excludeMissing = false) => {
      const entries = Object.entries(schema) as Entries<Schema<TIn, TOut>>;
      const obj = new Proxy(deserialized, {
        get: (target, prop) => {
          if (!(prop in target)) {
            throw new Missing(
              `Missing property ${String(prop)} in ${JSON.stringify(deserialized)}`);
          }
          if (typeof prop === "string") {
            return target[prop];
          }
        }
      })
      return Object.fromEntries(
        entries.map(([key, field]) => {
          try {
            if (typeof field === "string") {
              const tInKey = field as keyof TIn
              return [key, obj[tInKey]];
            }
            if (typeof field === "function") {
              return [key, field(obj)];
            }
          } catch (e) {
            if (excludeMissing && e instanceof Missing) {
              return []
            }
            throw e;
          }
          return [key, field];
        }).filter(el => el[0] !== undefined)
      ) as TOut;
    },
    schema
  }
}

export class IoModel <T>{
  declare public instance: T;

  constructor(instance: T) {
    this.instance = instance;
  }

  pick <To extends Partial<T>>(keys: (keyof T)[]): To {
    return Object.fromEntries(
      keys.map(key => [key, this.instance[key]])
    ) as To;
  }
}
