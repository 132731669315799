import {defineStore} from "pinia";

import {type Category, useCategories} from "@/rental/stores/categories";
import {computed, ref} from "vue";
import {useGenericApiCollection} from "@/app/api";

interface ISearchResult {
  object_type: string;
  object_id: string;
  label: string;
  icon?: string;
}

export class SearchResult implements ISearchResult{
  object_type: string;
  object_id: string;
  label: string;
  icon?: string;

  constructor(params: ISearchResult) {
    this.object_type = params.object_type;
    this.object_id = params.object_id;
    this.label = params.label;
    this.icon = params.icon;
  }

  static fromCategory(category: Category) {
    return new SearchResult({
      object_type: "category",
      object_id: category.id,
      label: category.name,
      icon: category.icon,
    });
  }
}

export const useSearch = defineStore("Search", () => {
  const searchApi = useGenericApiCollection<SearchResult, ISearchResult>("rental/search", {
    deserializer: (el) => new SearchResult(el),
    isCached: false,
  })
  const categories = ref<SearchResult[]>([]);
  const items = ref<SearchResult[]>([]);
  const query = ref<string | null>(null);

  return {
    categories,
    items,
    query,

    hasResults: computed(() => categories.value.length + items.value.length > 0),
    doQuery: async (q: string | null) => {
      query.value = q;
      if (!q || q.length === 0) {
        return;
      }
      const categoryStore = useCategories();
      items.value = await searchApi.list({
        search: q,
        limit: 10,
      })
      categories.value = categoryStore
        .search(q.toLowerCase())
        .map((el) => SearchResult.fromCategory(el))
        .slice(0, 10);
    }
  }
});
