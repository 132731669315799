import {computed, type MaybeRefOrGetter, Ref, toRef} from "vue";
import {objToEntries} from "@/helpers/transformers";


export type ClassInputs = object;
export const useClasses = <T extends ClassInputs>(
  inputs: MaybeRefOrGetter<T>,
  mapping: Record<keyof Partial<T>, string | Record<string, string>>,
  defaults: string[] = [],
) => {
  const r = toRef(inputs);
  return computed(() => {
    const result = objToEntries(mapping)
      .map(([key, value]) => {
        if (r.value[key]) {
          const candidate = r.value[key];
          if (typeof candidate === "boolean" && candidate) {
            return value;
          }
          if (
            typeof candidate === "string" &&
            value !== null &&
            typeof value === "object"
          ) {
            return value[candidate];
          }
        }
      })
      .filter((el) => el ?? false);
    return [...defaults, ...result];
  });
};
